$icon-font-path: "../fonts/";
$fa-font-path: "../fonts/";

/* Sass bower components will be injected here */
/* bower:scss */
@import "../bower_components/components-font-awesome/scss/fontawesome.scss";
/* endinject */
/* bower:sass */
/* endinject */
/* node:scss */
/* endinject */
/* node:sass */
/* endinject */
